import { Box, Typography } from '@material-ui/core'
import LanguageSwitch from './LanguageSwitch'
import constants from './constants-generated.json'

export default function BottomFooter() {
  return (
    <Box display="flex" justifyContent="space-between" paddingTop={3} paddingBottom={1}>
      <LanguageSwitch />
      <Typography align="right" color="textSecondary" variant="body2">
        {constants.SUPPORT_EMAIL_ADDRESS}
      </Typography>
    </Box>
  )
}
