import { omit, values } from 'lodash'
import { Language, Template, ThemeName } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const apexheat: Account = {
  name: 'Apex Heat Oy',
  emailDomain: 'apexheat.io',
  allowFileDownload: true,
  allowSolar: true,
  authorizeEveryoneFromDomain: true,
  allowedTemplates: [Template.EnergyAnalysis, ...values(omit(Template, 'EnergyAnalysis'))],
  allowedThemes: [ThemeName.Apexheat, ...values(omit(ThemeName, 'Apexheat'))],
  reportLanguages: [Language.Fi, Language.En],
}

export default apexheat
