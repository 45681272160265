import { Language, Template, ThemeName } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const cbre: Account = {
  name: 'CBRE',
  emailDomain: 'cbreim.com',
  authorizeEveryoneFromDomain: true,
  allowSolar: true,
  allowedTemplates: [Template.EnergyAnalysis, Template.Summary],
  allowedThemes: [ThemeName.Apexheat],
  reportLanguages: [Language.En, Language.Fi],
}

export default cbre
