import { Language, Template, ThemeName } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const kaivonporaus: Account = {
  name: 'Kaivonporaus',
  emailDomain: 'kaivonporaus.com',
  authorizeEveryoneFromDomain: true,
  allowSolar: true,
  allowedTemplates: [Template.EnergyAnalysis, Template.Summary],
  allowedThemes: [ThemeName.Saaristo],
  reportLanguages: [Language.Fi, Language.En],
}

export default kaivonporaus
