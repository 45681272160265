import { find } from 'lodash'
import impact from './companies/4impact'
import aalto from './companies/aalto'
import aitoenergia from './companies/aitoenergia'
import apexheat from './companies/apexheat'
import bridford from './companies/bridford'
import cambridge from './companies/cambridge'
import cbre from './companies/cbre'
import gebwell from './companies/gebwell'
import greencode from './companies/greencode'
import innovestor from './companies/innovestor'
import kaivonporaus from './companies/kaivonporaus'
import voltan from './companies/voltan'
import { Account } from './models'

const accounts: Record<string, Account> = {
  apexheat,
  voltan,
  kaivonporaus,
  cbre,
  impact,
  bridford,
  gebwell,
  aalto,
  greencode,
  aitoenergia,
  innovestor,
  cambridge,
}

export const getAccountByEmail = (email: string) => {
  const [, domain] = email.split('@')
  return find(accounts, (account) => domain === account.emailDomain)
}

export function isAuthorizedUser(email: string) {
  const account = getAccountByEmail(email)
  if (!account) return false
  if (account.authorizeEveryoneFromDomain) {
    return true
  }
  if (account.authorizeBySpecificEmail) {
    return !!account.authorizedUsers && account.authorizedUsers.includes(email)
  }
  return false
}

export function isDataFileDownloadAllowed(email: string) {
  const account = getAccountByEmail(email)
  if (!account) return false
  return !!account.allowFileDownload
}

export function isSolarAllowed(email: string) {
  const account = getAccountByEmail(email)
  if (!account) return false
  return !!account.allowSolar
}

export function getAccountDefaultValuesForForm(email: string) {
  const account = getAccountByEmail(email)
  if (!account) {
    const error = new Error(`account not found: ${email}`)
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  }

  return {
    defaultLanguage: account.reportLanguages[0],
    defaultTheme: account.allowedThemes[0],
    defaultTemplate: account.allowedTemplates[0],
    showSolar: !!account.allowSolar,
    themes: account.allowedThemes,
    templates: account.allowedTemplates,
    languages: account.reportLanguages,
  }
}
