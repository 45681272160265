import { Language, Template, ThemeName } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const aitoenergia: Account = {
  name: 'aitoenergia',
  emailDomain: 'aitoenergia.fi',
  authorizeEveryoneFromDomain: true,
  allowSolar: true,
  allowedTemplates: [Template.EnergyAnalysis, Template.Summary],
  allowedThemes: [ThemeName.Apexheat],
  reportLanguages: [Language.Fi, Language.En],
}

export default aitoenergia
