import { Language, Template, ThemeName } from '../../pdfs-graphql-generated'
import { Account } from '../models'

const impact: Account = {
  name: '4impact',
  emailDomain: '4impact.vc',
  authorizeEveryoneFromDomain: true,
  allowSolar: true,
  allowedTemplates: [Template.EnergyAnalysis, Template.Summary],
  allowedThemes: [ThemeName.Apexheat],
  reportLanguages: [Language.En, Language.Fi],
}

export default impact
